<template>
  <BaseTooltipModal v-if="bonus" :is-mobile="isMobile" :link="content.link">
    <template #title> {{ title }} </template>
    <template #subtitle>
      {{ content.subtitle }}
    </template>
    <TooltipModalBlock
      :title="t('bonuses.tooltipModalParameters')"
      data-t="parameters-block"
    >
      <TooltipModalBlockItem
        :termin="t('bonuses.tooltipRegularRewardCurrency')"
        :description="currencyCode"
        data-t="parameters-block-item"
      />
      <template v-if="bonus.type === 'cashback'">
        <TooltipModalBlockItem
          :termin="t('bonuses.cashbackFrequency')"
          :description="t('bonuses.cashbackFrequencyValue')"
          data-t="parameters-block-item"
        />
        <TooltipModalBlockItem
          v-if="formatedUpToAmount"
          :termin="t('bonuses.cashBackMaxBonusAmount')"
          :description="formatedUpToAmount"
          data-t="parameters-block-item"
        />
        <TooltipModalBlockItem
          v-if="percentAmount"
          :termin="t('bonuses.cashBackBaseMultiplier')"
          :description="percentAmount"
          data-t="parameters-block-item"
        />
        <TooltipModalBlockItem
          v-if="baseGgrMultiplier"
          :termin="t('bonuses.cashBackGgrMultiplier')"
          :description="baseGgrMultiplier"
          data-t="parameters-block-item"
        />
      </template>
      <TooltipModalBlockItem
        v-if="availableUntil"
        :termin="t('bonuses.reloadAvailableValidityUntil')"
        :description="availableUntil"
        class="disable-screenshot"
        data-t="parameters-block-item"
      />
    </TooltipModalBlock>
    <TooltipModalBlock
      v-if="isConditionsBlockVisible"
      :title="t('bonuses.tootlipModalConditions')"
      data-t="conditions-block"
    >
      <TooltipModalBlockItem
        v-if="totalProgressWithCurrency"
        :termin="t('bonuses.cashbackNeedBetAmount')"
        :description="totalProgressWithCurrency"
        data-t="conditions-block-item"
      />
      <TooltipModalBlockItem
        v-if="formattedMinBetRate"
        :termin="t('bonuses.tooltipModalSportMin')"
        :description="formattedMinBetRate"
        data-t="conditions-block-item"
      />
      <TooltipModalBlockItem
        v-if="formattedMaxBetRate"
        :termin="t('bonuses.tooltipModalSportMax')"
        :description="formattedMaxBetRate"
        data-t="conditions-block-item"
      />
      <TooltipModalBlockItem
        v-if="bonus.betSettings?.results?.length"
        :termin="t('bonuses.casinoBetResults')"
        data-t="conditions-block-item"
      >
        <div class="column" data-t="bet-results">
          <span v-for="result in bonus.betSettings?.results" :key="result">
            {{ result }}
          </span>
        </div>
      </TooltipModalBlockItem>
    </TooltipModalBlock>
  </BaseTooltipModal>
</template>

<script setup lang="ts">
import { useRegularBonusesStore } from '../../stores/useRegularBonusesStore'
import { useRegularBonus } from '../RegularBonusCard/useRegularBonus'
import BaseTooltipModal from '../TooltipModal/BaseTooltipModal.vue'
import TooltipModalBlock from '../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../TooltipModal/TooltipModalBlockItem.vue'

const props = defineProps<{
  id: string
}>()

const { t } = useI18n()

const { isMobile } = usePlatform()

const regularBonusesStore = useRegularBonusesStore()
const { regularBonuses } = storeToRefs(regularBonusesStore)

const bonus = computed(
  () => regularBonuses.value.filter((item) => item.id === props.id)[0],
)

const {
  isZeroAmount,
  currencyCode,
  bonusAmountWithCurrency,
  content,
  formattedMinBetRate,
  formattedMaxBetRate,
  totalProgressWithCurrency,
  formatedUpToAmount,
  percentAmount,
  baseGgrMultiplier,
  availableUntil,
} = useRegularBonus(bonus)

const title = computed(
  () =>
    `${content.value.title} ${
      isZeroAmount.value ? '' : bonusAmountWithCurrency.value
    }`,
)

const isConditionsBlockVisible = computed(
  () =>
    !!totalProgressWithCurrency.value ||
    !!formattedMinBetRate.value ||
    !!formattedMaxBetRate.value ||
    !!bonus.value.betSettings?.results?.length,
)
</script>
<style scoped>
.column {
  display: flex;
  flex-direction: column;
}
</style>
